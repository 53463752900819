import React from "react";
import "./skills.css";
import webUIDesign from "../../assets/website-design.png";
import appUIDesign from "../../assets/app-design.png";
import coding from "../../assets/developer.jpg"; // Add new image

const Skills = () => {
  return (
    <section id="skills">
      <span className="skillTitle">Why MasterSkillHub Stands Out</span>
      <span className="skillDesc">
        MasterSkillHub is a leading platform dedicated to transforming the skillsets of individuals and businesses through innovative tools, modern solutions, and expert guidance. We deliver exceptional experiences across web design, mobile app development, and full-stack development, creating opportunities to drive digital success.
      </span>
      <div className="skillBars">
        <span className="skillBar">
          <img src={webUIDesign} className="skillBarImg" alt="Web Design" />
          <div className="skillBarText">
            <h2>Innovative Web UI/UX Design</h2>
            <p>
              We create websites that captivate users with stunning visuals and intuitive user flows. At MasterSkillHub, we blend design aesthetics with functionality, ensuring that every user interaction is seamless, efficient, and memorable.
            </p>
          </div>
        </span>

        <span className="skillBar">
          <img src={appUIDesign} className="skillBarImg" alt="App Design" />
          <div className="skillBarText">
            <h2>Modern Mobile UI/UX Design</h2>
            <p>
              Our expertise in mobile app design ensures responsive, user-friendly interfaces that provide top-tier user experiences across devices. Every interaction is crafted to offer the best possible engagement for end users.
            </p>
          </div>
        </span>

        <span className="skillBar">
          <img src={coding} className="skillBarImg" alt="Full-Stack Development" />
          <div className="skillBarText">
            <h2>Complete Full-Stack Development</h2>
            <p>
              MasterSkillHub provides full-stack development solutions, building secure, scalable, and future-proof web applications. From frontend to backend, we leverage the latest technologies to deliver high-performance solutions tailored to your needs.
            </p>
          </div>
        </span>
      </div>
    </section>
  );
};

export default Skills;