import React, { useState } from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import './navbar.css';
import logo from '../../assets/mrskillhub.png'; // Replace with your actual logo path
import contactLogo from '../../assets/contact.png'; // Replace with your actual contact icon path

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo */}
      <img src={logo} className='logo' alt="Site Logo" />

      {/* Hamburger Icon for Mobile */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <span className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
      </div>

      {/* Desktop Menu */}
      <div className={`desktopMenu`}>
        <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Home</Link>
        <Link activeClass="active" to="skills" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">About</Link>

        {/* Direct Link to Services (No Dropdown) */}
        <Link activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Services</Link>

        <Link activeClass="active" to="portfolio" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Portfolio</Link>
        <Link activeClass="active" to="clients" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Clients</Link>

        {/* CEO Section */}
        <Link activeClass="active" to="ceo" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Who We Are</Link>
      </div>

      {/* Contact Button */}
      <button
        className="desktopMenuBtn"
        onClick={() => document.getElementById("contacts").scrollIntoView({ behavior: "smooth" })}
      >
        <img src={contactLogo} alt="Contact" className="desktopMenuImg" />
        Contact Me
      </button>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="mobileMenu">
          <Link to="intro" spy={true} smooth={true} offset={-100} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>Home</Link>
          <Link to="skills" spy={true} smooth={true} offset={-50} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>About</Link>

          {/* Direct Link to Services */}
          <Link to="services" spy={true} smooth={true} offset={-50} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>Services</Link>

          <Link to="portfolio" spy={true} smooth={true} offset={-50} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>Portfolio</Link>
          <Link to="clients" spy={true} smooth={true} offset={-50} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>Clients</Link>

          {/* CEO Section in Mobile */}
          <Link to="ceo" spy={true} smooth={true} offset={-50} duration={500} className="mobileMenuItem" onClick={toggleMobileMenu}>Who We Are</Link>
          
          <button
            className="mobileMenuBtn"
            onClick={() => {
              toggleMobileMenu();
              document.getElementById("contacts").scrollIntoView({ behavior: "smooth" });
            }}
          >
            Contact Me
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;