import React from 'react';
import './intro.css';
import bg from '../../assets/image.png';

const Intro = () => {
  return (
    <section id='intro'>
      <div className="introContent">
        <span className='hello'>Welcome to</span>
        <span className='introText'>
          <span className='introName'>MasterSkillHub</span><br /> 
          Empowering Skills, Building Futures.
        </span>
        <p className="introParag">
          MasterSkillHub is a platform dedicated to helping individuals develop new skills, manage tasks, 
          track progress, and connect with mentors. Whether you're looking to boost your career or learn a new skill, 
          we provide the resources and support you need.
        </p>

        {/* Button to download or view more details */}
        <a href="/path-to-more-details.pdf" target="_blank" rel="noopener noreferrer">
          <button className='btn'>
            <img src="https://img.icons8.com/ios/50/000000/document.png" className="btnImg" alt="Details Icon" /> 
            Learn More
          </button>
        </a>
      </div>

      <img src={bg} alt="MasterSkillHub" className="bg" />
    </section>
  );
};

export default Intro;