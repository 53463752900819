import React from 'react';
import './services.css';
import { FaTasks, FaGraduationCap, FaChartLine, FaUserFriends } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <div className="services-container">
        <h2 className="services-title">Our Services</h2>
        <p className="services-description">
          We provide a range of services to help individuals and organizations enhance their skills, track progress, and achieve success.
        </p>

        <div className="services-grid">
          {/* Service 1: Skill Development */}
          <div className="service-card">
            <div className="icon">
              <FaGraduationCap />
            </div>
            <h3 className="service-title">Skill Development</h3>
            <p className="service-description">
              Personalized learning experiences designed to help you build and master essential skills.
            </p>
          </div>

          {/* Service 2: Task Management */}
          <div className="service-card">
            <div className="icon">
              <FaTasks />
            </div>
            <h3 className="service-title">Task Management</h3>
            <p className="service-description">
              Organize tasks, set deadlines, and track your progress efficiently with our advanced tools.
            </p>
          </div>

          {/* Service 3: Progress Tracking */}
          <div className="service-card">
            <div className="icon">
              <FaChartLine />
            </div>
            <h3 className="service-title">Progress Tracking</h3>
            <p className="service-description">
              Monitor your achievements and milestones to stay on top of your goals and objectives.
            </p>
          </div>

          {/* Service 4: Mentorship */}
          <div className="service-card">
            <div className="icon">
              <FaUserFriends />
            </div>
            <h3 className="service-title">Mentorship</h3>
            <p className="service-description">
              Receive expert guidance from experienced mentors to help you excel in your field.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;