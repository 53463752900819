import React from "react";
import "./contacts.css";

const Contacts = () => {
  return (
    <section id="contacts" className="contacts-section">
      {/* Title and Description like Clients Section */}
      <div className="contacts-heading">
        <h2 className="contactsTitle">Get in Touch</h2>
        <p className="contactsDesc">
          Feel free to reach out via the form or any of the social platforms. I am always open to discussing new projects, ideas, or opportunities.
        </p>
      </div>

      <div className="contacts-wrapper">
        {/* Left Side: Contact Form */}
        <div className="contacts-left">
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message</label>
              <textarea id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>

          <div className="social-links">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </div>

        {/* Right Side: Google Map */}
        <div className="contacts-right">
          <iframe 
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1980649.6150841357!2d44.05050502713862!3d2.0469347369504576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3d5790ba29269b35%3A0x80b11bc2c2a27bc0!2sMogadishu%2C%20Somalia!5e0!3m2!1sen!2sso!4v1614601639141!5m2!1sen!2sso"
            width="100%"
            height="350"
            style={{ borderRadius: "15px", boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)", border: "none" }}
            allowFullScreen=""
            loading="lazy">
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default Contacts;