import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-content">
        
        {/* About Section */}
        <div className="footer-about">
          <h3>About MasterSkillHub</h3>
          <p>
            MasterSkillHub is your platform for personalized learning, helping users track progress, manage tasks, and grow professionally with the right tools and resources.
          </p>
        </div>

        {/* Quick Links */}
        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#clients">Clients</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <ul>
            <li><i className="fas fa-envelope"></i> info@masterskillhub.com</li>
            <li><i className="fas fa-phone"></i> +1 123-456-7890</li>
            <li><i className="fas fa-map-marker-alt"></i> 123 SkillHub St, San Francisco, CA</li>
          </ul>
        </div>

        {/* Newsletter Signup */}
        <div className="footer-newsletter">
          <h3>Stay Updated</h3>
          <p>Subscribe to our newsletter for the latest updates on courses, features, and more.</p>
          <form>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      {/* Social Media and Footer Bottom */}
      <div className="footer-bottom">
        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </div>
        <p>&copy; 2024 MasterSkillHub. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;