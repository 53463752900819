import React from 'react';
import './ceo.css';
import profilePicture from '../../assets/mrskillhub.png'; // Add company logo or relevant image here

const CEO = () => {
  return (
    <section id="ceo" className="ceo-section">
      <div className="ceo-container">
        {/* Profile Picture and Introduction */}
        <div className="profile-section">
          <img src={profilePicture} alt="MasterSkillHub" className="profile-picture" />
          <h2 className="ceo-name">MasterSkillHub</h2>
          <p className="ceo-title">Empowering Skill Development | Leading Digital Innovation</p>
          <p className="ceo-bio">
            MasterSkillHub is an innovative platform designed to help individuals and businesses thrive by offering world-class resources, tools, and expertise in various domains. We specialize in developing cutting-edge solutions, fostering technological growth, and empowering communities through education and mentorship. 
          </p>
        </div>

        {/* Platform Capabilities */}
        <div className="resume-section">
          <h3 className="section-title">Our Expertise</h3>
          <ul className="skills-list">
            <li>Comprehensive Full-Stack Development (React, Django, Node.js)</li>
            <li>Database Management and Optimization (MySQL, PostgreSQL, MongoDB)</li>
            <li>Custom API Design & Integration</li>
            <li>Project Management & Leadership for Digital Transformation</li>
            <li>Cloud-Based Solutions (AWS, Docker, Kubernetes)</li>
            <li>AI & Machine Learning Solutions for Advanced Analytics</li>
            <li>Mobile App Development (Flutter, React Native)</li>
            <li>Cybersecurity Best Practices for Enterprise Systems</li>
          </ul>

          {/* Key Projects */}
          <h3 className="section-title">Key Projects</h3>
          <div className="experience-item">
            <h4>Cloud-Based Learning Platform</h4>
            <p>MasterSkillHub’s learning platform has revolutionized how individuals gain skills, providing personalized learning paths and mentorship from industry experts.</p>
            <ul className="achievements-list">
              <li>Developed AI-driven recommendation systems for personalized content.</li>
              <li>Integrated real-time data analytics to track progress and performance.</li>
              <li>Scalable cloud infrastructure to support millions of users.</li>
            </ul>
          </div>

          <div className="experience-item">
            <h4>Enterprise Solutions for Businesses</h4>
            <p>
              We specialize in delivering enterprise-level solutions, from task management systems to large-scale healthcare platforms, all built with security and scalability in mind.
            </p>
          </div>

          <div className="experience-item">
            <h4>Smart City IoT Management</h4>
            <p>
              Leveraging the power of IoT, we developed solutions to manage smart city infrastructures like traffic and energy consumption, transforming urban environments.
            </p>
          </div>

          {/* Values and Vision */}
          <h3 className="section-title">Our Values</h3>
          <p>
            At MasterSkillHub, we believe in:
          </p>
          <ul className="values-list">
            <li><strong>Innovation:</strong> We continuously push the boundaries of technology to create impactful solutions.</li>
            <li><strong>Empowerment:</strong> We are committed to providing opportunities for growth and learning.</li>
            <li><strong>Collaboration:</strong> We work hand in hand with our clients to understand their needs and deliver exceptional results.</li>
            <li><strong>Excellence:</strong> Our goal is to deliver top-quality services that exceed expectations.</li>
          </ul>

          {/* Vision for the Future */}
          <h3 className="section-title">Vision for the Future</h3>
          <p>
            MasterSkillHub aims to become the global leader in skill development and technology-driven innovation. Our vision includes expanding into emerging markets, offering new solutions powered by AI, blockchain, and cybersecurity, and creating opportunities for individuals and organizations worldwide to grow and succeed in the digital age.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CEO;