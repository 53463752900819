import React from "react";
import "./clients.css";

const Clients = () => {
  const clients = [
    {
      name: "Tech Innovations",
      logo: "https://via.placeholder.com/150", // Replace with actual company logo
      testimonial: "Their team helped us achieve digital transformation in a short time, with remarkable support throughout the project."
    },
    {
      name: "Green Solutions",
      logo: "https://via.placeholder.com/150", // Replace with actual company logo
      testimonial: "We worked closely with MasterSkillHub to implement an IoT-powered solution for our environmental management project. The results were outstanding!"
    },
    {
      name: "Creative Minds Agency",
      logo: "https://via.placeholder.com/150", // Replace with actual company logo
      testimonial: "The user-friendly design and attention to detail were key to the success of our latest marketing campaign. Highly recommend their services!"
    },
    {
      name: "NextGen Ventures",
      logo: "https://via.placeholder.com/150", // Replace with actual company logo
      testimonial: "Their cloud-based platform was exactly what we needed to streamline our startup's operations. We are very pleased with the outcome."
    },
  ];

  return (
    <section id="clients" className="client-section">
      {/* Client Title and Description */}
      <div className="client-heading">
        <h2 className="clientTitle">Our Clients</h2>
        <p className="clientDesc">
          Explore testimonials from some of the clients we have worked with. They share their experiences with the projects we have successfully completed together.
        </p>
      </div>

      {/* Testimonials */}
      <div className="client-container">
        {clients.map((client, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-header">
              <img 
                src={client.logo} 
                alt={`${client.name} logo`} 
                className="testimonial-img" 
              />
              <div className="quote-icon">
                <i className="fas fa-quote-left"></i>
              </div>
            </div>
            <div className="testimonial-body">
              <p className="testimonial-text">
                {client.testimonial}
              </p>
              <div className="testimonial-footer">
                <h4>{client.name}</h4>
                <p>CEO, {client.name}</p>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Clients;