import React, { useState } from "react";
import "./portfolio.css";
import intelligenceImage from "../../assets/ims.webp"; // Replace with the actual image file name
import crimeAnalyticsImage from "../../assets/crime.webp"; // Replace with the actual image file name
import eGovernmentImage from "../../assets/egovernment.webp"; // Replace with the actual image file name
import healthcareImage from "../../assets/healthcare.webp"; // Replace with the actual image file name
import cybersecurityImage from "../../assets/cyber.webp"; // Replace with the actual image file name
import smartCityImage from "../../assets/smartcity.webp"; // Replace with the actual image file name
import eCommerceImage from "../../assets/cloudbased.webp"; // Replace with the actual image file name
import employeeMonitoringImage from "../../assets/employee.webp"; // Replace with the actual image file name

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const projects = [
    {
      title: "Intelligence Management System",
      category: "Enterprise Solutions",
      description: "An advanced platform for managing and analyzing intelligence data in real-time, designed for security agencies to streamline decision-making processes.",
      image: intelligenceImage, 
      link: "https://www.masterskillhub.com/intelligence-system"
    },
    {
      title: "AI-Powered Crime Analytics",
      category: "AI & Machine Learning",
      description: "A cutting-edge application that uses machine learning algorithms to predict and analyze criminal activity patterns, helping law enforcement agencies respond efficiently.",
      image: crimeAnalyticsImage,
      link: "https://www.masterskillhub.com/crime-analytics"
    },
    {
      title: "E-Government Services Platform",
      category: "Web Apps",
      description: "A fully integrated platform that provides secure online services for citizens, including tax filing, document verification, and application processing.",
      image: eGovernmentImage,
      link: "https://www.masterskillhub.com/e-government"
    },
    {
      title: "National Healthcare System",
      category: "Enterprise Solutions",
      description: "A robust healthcare management system that integrates hospital operations, patient management, and real-time data analytics for improved healthcare delivery.",
      image: healthcareImage,
      link: "https://www.masterskillhub.com/healthcare-system"
    },
    {
      title: "Cybersecurity Monitoring Dashboard",
      category: "Enterprise Solutions",
      description: "A comprehensive cybersecurity platform that monitors network activities, detects anomalies, and provides real-time alerts to prevent potential threats.",
      image: cybersecurityImage,
      link: "https://www.masterskillhub.com/cybersecurity-dashboard"
    },
    {
      title: "Smart City IoT Management",
      category: "AI & Machine Learning",
      description: "An IoT-driven solution for managing smart city infrastructure, including traffic management, waste management, and energy consumption monitoring.",
      image: smartCityImage,
      link: "https://www.masterskillhub.com/smart-city-iot"
    },
    {
      title: "Cloud-Based E-Commerce Solution",
      category: "Web Apps",
      description: "A scalable e-commerce platform designed to support millions of users, featuring secure payment gateways, real-time inventory tracking, and personalized user experiences.",
      image: eCommerceImage,
      link: "https://www.masterskillhub.com/ecommerce-solution"
    },
    {
      title: "Employee Performance Monitoring System",
      category: "Enterprise Solutions",
      description: "A real-time performance tracking system designed to measure productivity, provide feedback, and enhance employee engagement in large organizations.",
      image: employeeMonitoringImage,
      link: "https://www.masterskillhub.com/employee-monitoring"
    }
  ];

  const categories = ["All", "AI & Machine Learning", "Enterprise Solutions", "Web Apps"];

  const filteredProjects = selectedCategory === "All" 
    ? projects 
    : projects.filter(project => project.category === selectedCategory);

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <section id="portfolio">
      <h2 className="portfolioTitle">MasterSkillHub Projects</h2>
      <p className="portfolioDesc">
        Discover the innovative solutions MasterSkillHub offers. From enterprise-level intelligence systems to AI-powered applications, we create technology that drives impact. Explore our demos and see what we can do for you.
      </p>

      {/* Category Buttons */}
      <div className="portfolioCategories">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`categoryBtn ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Portfolio Items */}
      <div className="portfolioItems">
        {filteredProjects.map((project, index) => (
          <div key={index} className="portfolioItem" onClick={() => openModal(project)}>
            <img src={project.image} alt={project.title} className="portfolioImg" />
            <div className="portfolioContent">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedProject && (
        <div className="modalOverlay" onClick={closeModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <span className="modalClose" onClick={closeModal}>&times;</span>
            <img src={selectedProject.image} alt={selectedProject.title} className="modalImg" />
            <h3>{selectedProject.title}</h3>
            <p>{selectedProject.description}</p>
            <a href={selectedProject.link} target="_blank" rel="noopener noreferrer" className="portfolioLink">View Project</a>
          </div>
        </div>
      )}
    </section>
  );
};

export default Portfolio;