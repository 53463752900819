import Navbar from "./components/NavBar/navbar";
import Intro from "./components/Intro/intro";
import Skills from "./components/Skills/skills";
import Portfolio from "./components/Portfolio/portfolio";
import Clients from "./components/Clients/clients";
import Contacts from "./components/Contacts/contacts";
import Footer from "./components/Footer/footer";
import Services from "./components/Services/services";
import CEO from "./components/About/ceo";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro />
      <Skills />
      <Services />
      <Portfolio />
      <Clients />
      
      <CEO />
      <Contacts />
      <Footer />
    </div>
  );
}
window.addEventListener("scroll", function() {
  const navbar = document.querySelector('.navbar');
  if (window.scrollY > 50) {
      navbar.classList.add('scrolled');
  } else {
      navbar.classList.remove('scrolled');
  }
});
export default App;
